import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Package, Check } from 'lucide-react';
import { useAuthStore } from '../stores/auth';
import ReCAPTCHA from 'react-google-recaptcha';
import { PLANS } from '../config/plans';

export function Register() {
  const navigate = useNavigate();
  const { login } = useAuthStore();
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const [selectedPlan, setSelectedPlan] = useState('free');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    company: '',
    country: '',
    phone: ''
  });
  const [error, setError] = useState('');
  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!recaptchaValue) {
      setError('Por favor verifica que no eres un robot');
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setError('Las contraseñas no coinciden');
      return;
    }

    // Demo: crear cuenta y login automático
    login({
      id: '1',
      name: formData.name,
      email: formData.email,
      role: 'admin',
      avatar: ''
    });

    navigate('/app');
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <div className="text-center mb-12">
          <img 
            src="https://provalnet.net/wp-content/uploads/2024/11/logo.png" 
            alt="Axiloop Logo" 
            className="h-16 mx-auto mb-8"
          />
          <h2 className="text-3xl font-extrabold text-gray-900 dark:text-white">
            Comienza tu prueba gratuita
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600 dark:text-gray-400">
            O{' '}
            <button
              onClick={() => navigate('/login')}
              className="font-medium text-blue-600 hover:text-blue-500"
            >
              inicia sesión si ya tienes una cuenta
            </button>
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Formulario */}
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-8">
            {error && (
              <div className="mb-6 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                {error}
              </div>
            )}

            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Nombre completo
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  required
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-white"
                />
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Correo electrónico
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-white"
                />
              </div>

              <div>
                <label htmlFor="company" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Empresa
                </label>
                <input
                  id="company"
                  name="company"
                  type="text"
                  required
                  value={formData.company}
                  onChange={(e) => setFormData({ ...formData, company: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-white"
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    País
                  </label>
                  <input
                    id="country"
                    name="country"
                    type="text"
                    required
                    value={formData.country}
                    onChange={(e) => setFormData({ ...formData, country: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-white"
                  />
                </div>

                <div>
                  <label htmlFor="phone" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Teléfono
                  </label>
                  <input
                    id="phone"
                    name="phone"
                    type="tel"
                    required
                    value={formData.phone}
                    onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                    className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-white"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Contraseña
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  value={formData.password}
                  onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-white"
                />
              </div>

              <div>
                <label htmlFor="confirm-password" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                  Confirmar contraseña
                </label>
                <input
                  id="confirm-password"
                  name="confirm-password"
                  type="password"
                  required
                  value={formData.confirmPassword}
                  onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-white"
                />
              </div>

              <div className="flex justify-center">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey="your-recaptcha-site-key"
                  onChange={(value) => setRecaptchaValue(value)}
                />
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Crear cuenta
                </button>
              </div>
            </form>

            <div className="mt-6">
              <p className="text-center text-sm text-gray-500 dark:text-gray-400">
                ¿Necesitas ayuda? Contáctanos por WhatsApp{' '}
                <a 
                  href="https://wa.me/584128457542"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="font-medium text-blue-600 hover:text-blue-500"
                >
                  +58 412-8457542
                </a>
              </p>
            </div>
          </div>

          {/* Planes */}
          <div className="space-y-4">
            {Object.entries(PLANS).map(([id, plan]) => (
              <div
                key={id}
                className={`bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm border-2 transition-colors cursor-pointer ${
                  selectedPlan === id 
                    ? 'border-blue-500' 
                    : 'border-transparent hover:border-gray-200 dark:hover:border-gray-700'
                }`}
                onClick={() => setSelectedPlan(id)}
              >
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                    {plan.name}
                  </h3>
                  <span className="text-2xl font-bold text-gray-900 dark:text-white">
                    ${plan.price}
                    <span className="text-sm font-normal text-gray-500">/mes</span>
                  </span>
                </div>

                <ul className="mt-4 space-y-3">
                  <li className="flex items-start">
                    <Check className="h-5 w-5 text-green-500 mr-2" />
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {typeof plan.features.users === 'number' 
                        ? `${plan.features.users} usuarios`
                        : 'Usuarios ilimitados'}
                    </span>
                  </li>
                  <li className="flex items-start">
                    <Check className="h-5 w-5 text-green-500 mr-2" />
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {typeof plan.features.stores === 'number'
                        ? `${plan.features.stores} tiendas`
                        : 'Tiendas ilimitadas'}
                    </span>
                  </li>
                  <li className="flex items-start">
                    <Check className="h-5 w-5 text-green-500 mr-2" />
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {typeof plan.features.products === 'number'
                        ? `${plan.features.products} productos`
                        : 'Productos ilimitados'}
                    </span>
                  </li>
                  <li className="flex items-start">
                    <Check className="h-5 w-5 text-green-500 mr-2" />
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {plan.features.modules.length === 1 && plan.features.modules[0] === 'all'
                        ? 'Todos los módulos incluidos'
                        : `${plan.features.modules.length} módulos incluidos`}
                    </span>
                  </li>
                  <li className="flex items-start">
                    <Check className="h-5 w-5 text-green-500 mr-2" />
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      Almacenamiento: {plan.limits.storage}
                    </span>
                  </li>
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}