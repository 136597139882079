import { create } from 'zustand';

interface CompanyInfo {
  name: string;
  rif: string;
  address: string;
  phone: string;
  email: string;
  logo: string;
  receiptHeader: string;
  receiptFooter: string;
}

interface User {
  id: string;
  name: string;
  email: string;
  role: string;
  active: boolean;
}

interface Role {
  id: string;
  name: string;
  permissions: string[];
}

interface ConfigState {
  theme: 'light' | 'dark';
  currency: 'USD' | 'VES';
  exchangeRate: number;
  taxes: {
    IVA: number;
  };
  companyInfo: CompanyInfo;
  users: User[];
  roles: Role[];
  updateTheme: (theme: 'light' | 'dark') => void;
  updateExchangeRate: (rate: number) => void;
  updateCurrency: (currency: 'USD' | 'VES') => void;
  updateTaxes: (taxes: { IVA: number }) => void;
  updateCompanyInfo: (info: Partial<CompanyInfo>) => void;
  addUser: (user: User) => void;
  updateUser: (id: string, updates: Partial<User>) => void;
  deleteUser: (id: string) => void;
  addRole: (role: Role) => void;
  updateRole: (id: string, updates: Partial<Role>) => void;
  deleteRole: (id: string) => void;
}

export const useConfigStore = create<ConfigState>((set) => ({
  theme: 'light',
  currency: 'USD',
  exchangeRate: 35.5,
  taxes: {
    IVA: 16,
  },
  companyInfo: {
    name: 'Provalnet',
    rif: 'J-123456789',
    address: 'Caracas, Venezuela',
    phone: '+58 412-1234567',
    email: 'info@provalnet.com',
    logo: 'https://provalnet.net/wp-content/uploads/2024/11/logo.png',
    receiptHeader: '',
    receiptFooter: 'Gracias por su compra',
  },
  users: [
    {
      id: '1',
      name: 'Admin Demo',
      email: 'admin@demo.com',
      role: 'admin',
      active: true,
    }
  ],
  roles: [
    {
      id: '1',
      name: 'Administrador',
      permissions: ['all'],
    },
    {
      id: '2',
      name: 'Vendedor',
      permissions: ['pos', 'inventory.read'],
    }
  ],
  
  updateTheme: (theme) => set({ theme }),
  updateExchangeRate: (rate) => set({ exchangeRate: rate }),
  updateCurrency: (currency) => set({ currency }),
  updateTaxes: (taxes) => set({ taxes }),
  updateCompanyInfo: (info) => set((state) => ({
    companyInfo: { ...state.companyInfo, ...info }
  })),
  
  addUser: (user) => set((state) => ({
    users: [...state.users, user]
  })),
  updateUser: (id, updates) => set((state) => ({
    users: state.users.map(user => 
      user.id === id ? { ...user, ...updates } : user
    )
  })),
  deleteUser: (id) => set((state) => ({
    users: state.users.filter(user => user.id !== id)
  })),
  
  addRole: (role) => set((state) => ({
    roles: [...state.roles, role]
  })),
  updateRole: (id, updates) => set((state) => ({
    roles: state.roles.map(role =>
      role.id === id ? { ...role, ...updates } : role
    )
  })),
  deleteRole: (id) => set((state) => ({
    roles: state.roles.filter(role => role.id !== id)
  })),
}));