import React, { useState } from 'react';
import { ShoppingCart, Package, DollarSign, Receipt } from 'lucide-react';
import { ProductSearch } from './ProductSearch';
import { CartPanel } from './CartPanel';
import { PaymentModal } from './PaymentModal';
import { CustomerSelect } from './CustomerSelect';
import { useInventoryStore } from '../../inventory/stores/inventoryStore';
import { useConfigStore } from '../../config/stores/configStore';

export function POSDashboard() {
  const { selectedWarehouse } = useInventoryStore();
  const { currency, exchangeRate, taxes } = useConfigStore();
  const [showPayment, setShowPayment] = useState(false);
  const [cart, setCart] = useState<any[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);

  const handleAddToCart = (product: any, quantity: number = 1) => {
    const existingItem = cart.find(item => item.productId === product.id);
    
    if (existingItem) {
      setCart(cart.map(item =>
        item.productId === product.id
          ? { ...item, quantity: item.quantity + quantity }
          : item
      ));
    } else {
      setCart([...cart, {
        productId: product.id,
        name: product.name,
        price: product.baseUnit.price.USD,
        quantity,
        unitId: product.baseUnit.id
      }]);
    }
  };

  const handleUpdateQuantity = (productId: string, quantity: number) => {
    if (quantity <= 0) {
      setCart(cart.filter(item => item.productId !== productId));
    } else {
      setCart(cart.map(item =>
        item.productId === productId
          ? { ...item, quantity }
          : item
      ));
    }
  };

  const handleRemoveItem = (productId: string) => {
    setCart(cart.filter(item => item.productId !== productId));
  };

  const handleCheckout = () => {
    if (!selectedCustomer) {
      alert('Por favor seleccione un cliente antes de procesar la venta');
      return;
    }
    setShowPayment(true);
  };

  const handlePaymentComplete = (paymentDetails: any) => {
    const sale = {
      id: crypto.randomUUID(),
      date: new Date().toISOString(),
      customer: selectedCustomer,
      items: cart,
      subtotal,
      taxAmount,
      total,
      totalBs: total * exchangeRate,
      payment: paymentDetails,
      exchangeRate,
      warehouseId: selectedWarehouse,
      status: 'completed'
    };
    
    console.log('Venta completada:', sale);
    setCart([]);
    setShowPayment(false);
    setSelectedCustomer(null);
  };

  const subtotal = cart.reduce((sum, item) => sum + (item.price * item.quantity), 0);
  const taxAmount = subtotal * ((taxes?.IVA || 16) / 100); // Use default 16% if taxes.IVA is undefined
  const total = subtotal + taxAmount;

  return (
    <div className="h-[calc(100vh-4rem)] flex">
      {/* Panel Principal */}
      <div className="flex-1 flex flex-col">
        {/* Header */}
        <div className="bg-white dark:bg-gray-800 p-4 shadow">
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-bold text-gray-900 dark:text-white flex items-center gap-2">
              <ShoppingCart className="h-6 w-6" />
              Punto de Venta
            </h1>
            <div className="flex items-center gap-4">
              <div className="text-sm text-gray-500 dark:text-gray-400">
                Tasa del día: <span className="font-medium">Bs. {exchangeRate}</span>
              </div>
              <div className="text-sm text-gray-500 dark:text-gray-400">
                IVA: <span className="font-medium">{taxes?.IVA || 16}%</span>
              </div>
            </div>
          </div>
        </div>

        {/* Selector de Cliente */}
        <div className="p-4">
          <CustomerSelect onCustomerSelect={setSelectedCustomer} />
        </div>

        {/* Búsqueda de Productos */}
        <div className="p-4">
          <ProductSearch onProductSelect={handleAddToCart} />
        </div>

        {/* Estadísticas Rápidas */}
        <div className="grid grid-cols-3 gap-4 p-4">
          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
            <div className="flex items-center gap-3">
              <Receipt className="h-8 w-8 text-blue-500" />
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400">Ventas del Día</p>
                <p className="text-xl font-semibold text-gray-900 dark:text-white">0</p>
              </div>
            </div>
          </div>
          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
            <div className="flex items-center gap-3">
              <DollarSign className="h-8 w-8 text-green-500" />
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400">Total del Día</p>
                <p className="text-xl font-semibold text-gray-900 dark:text-white">${0.00}</p>
              </div>
            </div>
          </div>
          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
            <div className="flex items-center gap-3">
              <Package className="h-8 w-8 text-purple-500" />
              <div>
                <p className="text-sm text-gray-500 dark:text-gray-400">Productos Vendidos</p>
                <p className="text-xl font-semibold text-gray-900 dark:text-white">0</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Panel del Carrito */}
      <CartPanel
        items={cart}
        onUpdateQuantity={handleUpdateQuantity}
        onRemoveItem={handleRemoveItem}
        onCheckout={handleCheckout}
        total={total}
        subtotal={subtotal}
        taxAmount={taxAmount}
        totalBs={total * exchangeRate}
        currency={currency}
      />

      {/* Modal de Pago */}
      {showPayment && (
        <PaymentModal
          total={total}
          onClose={() => setShowPayment(false)}
          onComplete={handlePaymentComplete}
        />
      )}
    </div>
  );
}