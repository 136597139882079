import React, { useState } from 'react';
import { Product, ProductImage, UnitOfMeasure, UnitType } from '../types';
import { useCategoryStore } from '../stores/categoryStore';

interface ProductFormProps {
  onSubmit: (product: Partial<Product>) => void;
  onClose: () => void;
  initialData?: Partial<Product>;
}

interface ProductFormData {
  sku: string;
  name: string;
  description: string;
  categoryId: string;
  minStock: number;
  maxStock: number;
  mainImage: ProductImage | null;
  bannerImage: ProductImage | null;
  gallery: ProductImage[];
  baseUnit: UnitOfMeasure;
  units: UnitOfMeasure[];
}

export function ProductForm({ onSubmit, onClose, initialData }: ProductFormProps) {
  const { getFlattenedCategories } = useCategoryStore();
  const flattenedCategories = getFlattenedCategories();

  const [formData, setFormData] = useState<ProductFormData>({
    sku: initialData?.sku || '',
    name: initialData?.name || '',
    description: initialData?.description || '',
    categoryId: initialData?.categoryId || '',
    minStock: initialData?.minStock || 0,
    maxStock: initialData?.maxStock || 0,
    mainImage: initialData?.mainImage || null,
    bannerImage: initialData?.bannerImage || null,
    gallery: initialData?.gallery || [],
    baseUnit: initialData?.baseUnit || {
      id: crypto.randomUUID(),
      type: 'unit' as UnitType,
      name: 'Unidad',
      conversionFactor: 1,
      price: { USD: 0 },
      isGenerated: false
    },
    units: initialData?.units || []
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const handleImageUpload = (type: 'main' | 'banner' | 'gallery') => (imageData: { url: string; filename: string; path: string }) => {
    if (type === 'gallery') {
      setFormData({
        ...formData,
        gallery: [...formData.gallery, {
          id: crypto.randomUUID(),
          url: imageData.url,
          alt: formData.name,
          type: 'gallery'
        }]
      });
    } else {
      const image: ProductImage = {
        id: crypto.randomUUID(),
        url: imageData.url,
        alt: formData.name,
        type
      };
      setFormData({
        ...formData,
        [type === 'main' ? 'mainImage' : 'bannerImage']: image
      });
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg max-w-4xl w-full max-h-[90vh] overflow-auto">
        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          {/* Resto del JSX del formulario permanece igual */}
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <label className="block text-sm font-medium text-gray-700">SKU</label>
              <input
                type="text"
                value={formData.sku}
                onChange={(e) => setFormData({ ...formData, sku: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700">Nombre</label>
              <input
                type="text"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Categoría</label>
              <select
                value={formData.categoryId}
                onChange={(e) => setFormData({ ...formData, categoryId: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              >
                <option value="">Seleccione una categoría</option>
                {flattenedCategories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Stock Mínimo</label>
              <input
                type="number"
                value={formData.minStock}
                onChange={(e) => setFormData({ ...formData, minStock: Number(e.target.value) })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                min="0"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Stock Máximo</label>
              <input
                type="number"
                value={formData.maxStock}
                onChange={(e) => setFormData({ ...formData, maxStock: Number(e.target.value) })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                min="0"
              />
            </div>

            <div className="col-span-2">
              <label className="block text-sm font-medium text-gray-700">Descripción</label>
              <textarea
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            </div>

            {/* Galería de imágenes */}
            <div className="col-span-2">
              <div className="grid grid-cols-3 gap-4">
                {formData.gallery.map((image: ProductImage) => (
                  <div key={image.id} className="relative">
                    <img
                      src={image.url}
                      alt={image.alt}
                      className="h-24 w-24 object-cover rounded"
                    />
                    <button
                      type="button"
                      onClick={() => setFormData({
                        ...formData,
                        gallery: formData.gallery.filter(img => img.id !== image.id)
                      })}
                      className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1"
                    >
                      <span className="sr-only">Eliminar</span>
                      ×
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700"
            >
              {initialData ? 'Guardar Cambios' : 'Crear Producto'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}